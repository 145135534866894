function clickDropdownMenu() {
  document.getElementById("dropMenu").classList.toggle("show-menu");
  document.getElementById("menu-button-arrow").classList.toggle("open");
}

function handleHomepageHeader(templateUrl) {
  var headerImage = new Image();
  var imageUnderlay = document.querySelector('.homepage-banner .image-underlay');

  if (imageUnderlay) {
    imageUnderlay.classList.remove('loaded');

    if (window.screen.width >= 1440) {
      headerImage.src = templateUrl + "/dist/images/homepage/Home-MainImage-1-1920px.jpg";
    } else if (window.screen.width >= 1280) {
      headerImage.src = templateUrl + "/dist/images/homepage/Home-MainImage-1-1440px.jpg";
    } else {
      headerImage.src = templateUrl + "/dist/images/homepage/Home-MainImage-1-1280px.jpg";
    }

    headerImage.onload = function() {
      imageUnderlay.classList.add('loaded');
    };
  }
}
